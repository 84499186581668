export default {

    isBasicQuestion(card){
        return +(card?.card_layout_id) === 1;
    },
    hasAnswer(card) {
        if (!card?.card_layout_id) return false;

        if( this.isBasicQuestion(card) )
            return true;

        return false;
    },
    hasFeedForward(card) {
        if (!card?.card_layout_id) return false;

        if( this.isBasicQuestion(card) )
            return true;

        return false;
    },
    hasFeedBack(card) {
        if (!card?.card_layout_id) return false;

        if( this.isBasicQuestion(card) )
            return true;

        return false;
    }

}
